@font-face {
  font-family: 'INTERMARCHE-Bold';
  src: local('INTERMARCHE-Bold'),
    url(./fonts/INTERMARCHE/INTERMARCHE-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'INTERMARCHE-Regular';
  src: local('INTERMARCHE-Regular'),
    url(./fonts/INTERMARCHE/INTERMARCHE-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'),
    url(./fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'),
    url(./fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
}

html,
body {
  margin: 0;
  font-family: 'INTERMARCHE-Bold';
  width: 100%;
  height: 100%;
}
