.ss-container {
}

.ss-img {
  position: absolute;
}

.ss-icon {
  position: absolute;
  z-index: 999;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.ss-message {
  position: absolute;
  width: 450px;
  height: 40px;
  z-index: 999;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  background-color: black;
  color: white;
  margin-bottom: 10px;
  font-family: Intermarche-Regular;
  font-size: 16px;
  cursor: default;
}

.ss-message-p {
  margin: 0;
  line-height: 40px;
}
