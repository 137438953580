.um-header {
  background: url('../../assets/images/KRAFT.png') repeat top center;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'INTERMARCHE-Bold';
}

.um-header-logo {
  justify-self: flex-start;
  margin-left: 37px;
}

.um-header-logo-img {
  vertical-align: middle;
}

.um-header-title {
  justify-self: flex-start;
  margin-left: 20px;
  /* width: 265px; */
  height: 126px;
  font-size: 40px;
  line-height: 42px;
}

.um-header-title span {
  color: var(--theme-color-red);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .um-header-heading {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;

    height: 43px;
    width: 400px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
    white-space: nowrap;
  }
}

@media (max-width: 1200px) {
  .um-header-heading {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    height: 43px;
    width: 400px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    margin-bottom: 0px;
  }
}
