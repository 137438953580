.p-possibilities {
  width: 100%;
  background-color: var(--theme-color-extremelylightgrey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 15px;
}

.p-possibilities-avecsans {
  width: 100%;
  background-color: var(--theme-color-extremelylightgrey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.p-possibilities-avecsans-radio-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  /* width: 100%; */
  flex-wrap: wrap;
}

.p-h3 {
  font-family: 'INTERMARCHE-Bold';
  letter-spacing: 0;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 88px;
  font-size: 24px;
}

.p-option-container {
  align-self: center;
}

.p-option {
  width: 384px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  margin-bottom: 20px;
  cursor: pointer;
}

.p-option-active {
  border-color: var(--theme-color-red);
}

.p-option-inactive {
  border-color: var(--theme-color-lightgrey);
}

.p-option-txt {
  margin: 0px;
  font-family: 'OpenSans-SemiBold';
  font-size: 13px;
  margin-left: 10px;
  white-space: pre-wrap;
}

.p-option-img {
  margin-right: 10px;
  width: 100px;
  height: 70px;
  /* background-color: #8d877e; */
}

.p-radio-subcontainer {
  box-sizing: border-box;
  height: 60px;
  width: 206px;
  border: 2px solid #000000;
  border-radius: 9px;
  background-color: #ffffff;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
}

.p-radio-subcontainer-active {
  box-sizing: border-box;
  height: 60px;
  width: 206px;
  border: 2px solid #ff1e24;
  border-radius: 9px;
  background-color: #ff1e24;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
}

.p-radio {
  margin-left: 15px;
}

.p-radio-label-active {
  margin: 0px;
  color: white;
  font-family: 'Intermarche-Regular';
  font-size: 18px;
  line-height: 22px;

  cursor: pointer;
}

.p-radio-label {
  margin: 0px;
  /* color: black; */
  font-family: 'Intermarche-Regular';
  font-size: 18px;
  line-height: 22px;

  cursor: pointer;
}

.p-radio-margin-right {
  margin-right: 31px;
  margin-top: 15px;
  margin-bottom: 15px;
}
