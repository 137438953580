.pose-poses-active {
  width: 100%;
  background-color: var(--theme-color-extremelylightgrey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 30px;
}

.pose-poses {
  display: none;
}

.pose-h3 {
  margin-top: 0px;
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  width: 354px;
  height: 25px;
  border-bottom: solid 2px black;
}

.pose-option {
  width: 354px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  margin-bottom: 15px;
  cursor: pointer;
}

.pose-option-active {
  border-color: var(--theme-color-red);
}

.pose-option-inactive {
  border-color: var(--theme-color-lightgrey);
}

.pose-option-txt {
  font-family: 'OpenSans-SemiBold';
  font-size: 13px;
  margin-left: 10px;
  white-space: pre-wrap;
}

.pose-option-img {
  margin-right: 20px;
  width: 100px;
  height: 70px;
  /* background-color: #8d877e; */
}
