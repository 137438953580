.smn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  flex-wrap: wrap;
}

.smn-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.smn-pre {
  font-family: 'OpenSans-SemiBold';
  font-size: 14px;
  margin: 0px;
}

.smn-p {
  font-family: 'OpenSans-SemiBold';
  font-size: 14px;
  margin: 0px;
}

.smn-p-active {
  color: var(--theme-color-red);
}

.smn-p-disabled {
  color: lightgray;
}
