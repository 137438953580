.synthese-container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

.synthese-header {
  background: url('../../assets/images/KRAFT.png') repeat top center;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'INTERMARCHE-Bold';
}

.synthese-header-logo {
  justify-self: flex-start;
  margin-left: 37px;
}

.synthese-header-logo-img {
  vertical-align: middle;
}

.synthese-header-title {
  justify-self: flex-start;
  margin-left: 20px;
  width: 265px;
  height: 126px;
  font-size: 40px;
  line-height: 42px;
}

.synthese-header-title span {
  color: var(--theme-color-red);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .synthese-header-heading {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    height: 43px;
    width: 122px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .synthese-header-heading {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    height: 43px;
    width: 122px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
  }
}

.synthese-selections-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.synthese-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 915px; */
  width: 90%;
}

.synthese-classification-scene {
  width: 100%;
}

.synthese-classification-scene-p {
  height: 29px;
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  border-bottom: 2px solid black;
  margin: 0px;
}

.synthese-scene-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f6f6f6;
}

.synthese-scene {
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 20px;
  margin-bottom: 40px;
  width: 443px;
  min-height: 700px;
}

.synthese-scene-p {
  /* height: 29px; */
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  border-bottom: 2px solid black;
  margin: 0px;
  margin-bottom: 15px;
}

.synthese-scene-images-container {
  width: 960px;
  height: 540px;
  margin-right: 40px;
  margin-bottom: 100px;
}

.synthese-element-modifie {
}

.synthese-element-modifie-type-modification-p {
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0px;
}

.synthese-possibilities-p {
  color: #000000;
  font-family: 'INTERMARCHE-Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0px;
  margin-left: 20px;
}

.synthese-poses-title-p {
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0px;
}

.synthese-poses-p {
  color: #000000;
  font-family: 'INTERMARCHE-Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0px;
}

.synthese-poses-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  margin-left: 20px;
}

.synthese-projectinfo-category {
  height: 29px;
  width: 443px;
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  margin: 0px;
  margin-top: 35px;
}

.synthese-projectinfo-info-container {
  margin: 0px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 13px;
  max-width: 443px;
}

.synthese-projectinfo-info-p1 {
  font-family: 'INTERMARCHE-Bold';
}

.synthese-projectinfo-info-p2 {
  font-family: 'INTERMARCHE-Regular';
}

.synthese-projectinfo-info-p2-v2 {
  font-family: 'INTERMARCHE-Regular';
  display: block;
  margin-left: 20px;
}

.synthese-title {
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  margin: 0px;
  padding-top: 20px;
}

.synthese-projectinfo-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  width: 915px;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.synthese-print-btn {
  height: 60px;
  width: 443px;
  border-radius: 9px;
  background-color: #ff1e24;
  color: #ffffff;
  font-family: 'INTERMARCHE-Bold';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}

.synthese-print-btn:focus {
  outline: none;
}

.line-break {
  display: block;
}

.synthese-comment {
  text-align: right;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
}

.synthese-encadre {
  height: 75px;
  border: 2px black solid;
}

@media print {
  .print-page-break {
    display: block;
    page-break-before: always;
  }

  .print-synthese-scene-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    page-break-before: always;
    height: 700px !important;
  }

  .dont-show {
    display: none;
  }

  .print-width {
    width: 350px;
  }

  .print-vignette-size {
    width: 100px;
    height: 70px;
  }

  .print-ss-img {
    width: 720px;
    height: 405px;
  }

  .print-synthese-comment {
    width: 720px !important;
  }

  .print-synthese-encadre {
    height: 175px;
  }
}
