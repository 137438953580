.header {
  background: url('../../assets/images/KRAFT.png') repeat top center;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'INTERMARCHE-Bold';
}

.header-logo {
  justify-self: flex-start;
  margin-left: 37px;
  /* text-align: center; */
}

.header-logo-img {
  vertical-align: middle;
}

.header-title {
  justify-self: flex-start;
  margin-left: 20px;
  /* width: 265px; */
  height: 126px;
  font-size: 40px;
  line-height: 42px;
}

.header-title span {
  color: var(--theme-color-red);
  white-space: nowrap;
}

@media (min-width: 1400px) {
  .header-part2 {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 197px;
  }
}

@media (max-width: 1400px) {
  .header-part2 {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 50px;
  }
}

.header-part2-top {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-save-btn {
  outline: none;
  border: none;
  width: 236px;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  background-color: var(--theme-color-red);
  color: #ffffff;
  margin-right: 31px;
  font-family: 'INTERMARCHE-Bold';
  cursor: pointer;
}

.header-save-btn:focus {
  outline: none;
}

.logoutIcon{
  width: 45px;
  height: auto;
  margin-right: 32px;
  cursor:pointer
}