.fm-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

.fm-header {
  background: url('../../assets/images/KRAFT.png') repeat top center;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'INTERMARCHE-Bold';
}

.fm-header-logo {
  justify-self: flex-start;
  margin-left: 37px;
}

.fm-header-logo-img {
  vertical-align: middle;
}

.fm-header-title {
  justify-self: flex-start;
  margin-left: 20px;
  /* width: 265px; */
  height: 126px;
  font-size: 40px;
  line-height: 42px;
}

.fm-header-title span {
  color: var(--theme-color-red);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .fm-header-heading {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;

    height: 43px;
    width: 122px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .fm-header-heading {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    height: 43px;
    width: 122px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
    margin-bottom: 0px;
  }
}

.fm-menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.fm-menu-element {
  max-width: 620px;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-decoration: none;
  color: black;
}

.fm-menu-element:hover {
  max-width: 620px;
  width: 100%;
  height: 100%;
  background-color: #ff1e24;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-decoration: none;
}

.fm-menu-element-p {
  width: 260px;
  margin: 0px;
  font-family: 'INTERMARCHE-Bold';
  margin-top: 25px;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 43px;
}

#fm-menu-firstelement {
}

#fm-menu-secondelement {
  margin-left: 30px;
  margin-right: 30px;
}

#fm-menu-thirdelement {
}
#manageusers {
  position: absolute;
  right: 20px;
}
