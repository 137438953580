.tm-container {
}

.tm-h2 {
  font-family: 'INTERMARCHE-Bold';
  font-size: 36px;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 5px;
}
