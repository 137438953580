.rn-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rn-btn-left {
  background-color: transparent;
  width: 206px;
  height: 50px;
  font-family: 'INTERMARCHE-Bold';
  font-size: 16px;
  border-color: var(--theme-color-lightgrey);
  border-style: solid;
  border-width: 2px;
  outline: 0;
  cursor: pointer;
}

.rn-btn-left:focus {
  outline: none;
}

/* .rn-btn-right:hover {
  border-color: rgba(216, 216, 216, 0.7);
} */

.rn-btn-middle {
  background-color: transparent;
  width: 324px;
  height: 50px;
  font-family: 'INTERMARCHE-Bold';
  font-size: 16px;
  border-color: var(--theme-color-lightgrey);
  border-style: solid;
  border-width: 2px;
  outline: 0;
  cursor: pointer;
}

.rn-btn-middle:focus {
  outline: none;
}

/* .rn-btn-middle:hover {
  border-color: rgba(216, 216, 216, 0.7);
} */

.rn-btn-firstright {
  background-color: black;
  width: 206px;
  height: 50px;
  font-family: 'INTERMARCHE-Regular';
  font-size: 16px;
  color: #ffffff;
  border: none;
  outline: 0;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.rn-btn-firstright:focus {
  outline: none;
}

.rn-btn-right {
  background-color: var(--theme-color-red);
  width: 206px;
  height: 50px;
  font-family: 'INTERMARCHE-Bold';
  font-size: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.rn-btn-right:focus {
  outline: none;
}

/* .rn-btn-left:hover {
  background-color: rgb(255, 30, 36, 0.7);
} */

.rn-btn-right-container {
  display: flex;
  flex-direction: row;
}
