.login-container {
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/fond_craft.jpg') no-repeat;
  background-size: 100% 100%;
  font-family: 'INTERMARCHE-Bold';
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
}

.login-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  padding-top: 50px;
  cursor:pointer;
}

.login-header-title {
  margin-left: 20px;
  /* width: 265px; */
  height: 126px;
  font-size: 40px;
  line-height: 42px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.login-header-title span {
  color: var(--theme-color-red);
  white-space: nowrap;
}

.login-subcontainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-title {
  height: 43px;

  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  margin-bottom: 30px;
}

.login-label {
  height: 17px;
  width: 428px;
  color: #000000;
  font-family: 'INTERMARCHE-Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 7px;
}

.login-input {
  box-sizing: border-box;
  height: 60px;
  width: 443px;
  border: 2px solid #000000;
  border-radius: 9px;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding-left: 15px;
  font-family: 'INTERMARCHE-Regular';
  line-height: 22px;
}

.login-input::placeholder {
  height: 22px;
  width: 413px;
  color: #919191;
  font-family: 'INTERMARCHE-Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
}

.login-input:-webkit-input-placeholder {
  padding-left: 15px;
}

.login-input:focus {
  outline: none;
}

.login-button {
  height: 60px;
  width: 443px;
  border-radius: 9px;
  background-color: #ff1e24;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 60px;
}

.login-button:focus {
  outline: none;
}

.login-button span {
  height: 22px;
  width: 80px;
  color: #ffffff;
  font-family: 'INTERMARCHE-Regular';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.passwordreminder{
  margin:15px;
  text-align: right;
  width:100%;
}

.passwordreminder a{
  cursor:pointer;
}

