.np-container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

.np-header {
  background: url('../../assets/images/KRAFT.png') repeat top center;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'INTERMARCHE-Bold';
  margin-bottom: 20px;
}

.np-header-logo {
  justify-self: flex-start;
  margin-left: 37px;
  z-index: 999;
}

.np-header-logo-img {
  vertical-align: middle;
}

.np-header-title {
  justify-self: flex-start;
  margin-left: 20px;
  width: 265px;
  height: 126px;
  font-size: 40px;
  line-height: 42px;
}

.np-header-title span {
  color: var(--theme-color-red);
  white-space: nowrap;
}

.np-header-heading {
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 36px;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  margin: 0px;
}

.np-header-subheading {
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0px;
}

@media (min-width: 1400px) {
  .np-header-main {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1400px) {
  .np-header-main {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.np-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.np-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 915px;
  background-color: #f6f6f6;
  margin-bottom: 5px;
}

.np-element-title {
  padding-top: 30px;
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 36px;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
}

.np-form-input-text {
  color: #000000;
  font-family: 'INTERMARCHE-Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 7px;
}

.np-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 30px;
}

.np-input {
  box-sizing: border-box;
  height: 60px;
  width: 443px;
  border: 2px solid #000000;
  border-radius: 9px;
  background-color: #ffffff;
  /* margin-bottom: 30px; */
  padding-left: 15px;
  padding-right: 15px;

  font-family: 'INTERMARCHE-Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  outline: none;
}

.np-big-input {
  height: 180px;
  padding-top: 19px;
}

.np-input::placeholder {
  color: #919191;
}

.np-input.required:invalid {
  border-color: #ff6d00;
}

.np-radio-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 30px;
}

.np-radio-subcontainer {
  box-sizing: border-box;
  height: 60px;
  width: 206px;
  border: 2px solid #000000;
  border-radius: 9px;
  background-color: #ffffff;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
}

.np-radio-subcontainer-disabled {
  background-color: lightgray;
  cursor: default;
}

.np-radio-subcontainer-active {
  box-sizing: border-box;
  height: 60px;
  width: 206px;
  border: 2px solid #ff1e24;
  border-radius: 9px;
  background-color: #ff1e24;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
}

.np-radio-margin-right {
  margin-right: 31px;
}

.np-radio-margin-bottom {
  margin-bottom: 30px;
}

.np-radio-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 443px;
}

.np-radio-title-text {
  color: #000000;
  font-family: 'INTERMARCHE-Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0px;
  margin-bottom: 7px;
}

.np-submit {
  text-decoration: none;
  margin-top: 55px;
  margin-bottom: 110px;
  height: 60px;
  width: 443px;
  background-color: #ff1e24;
  border: none;

  color: #ffffff;
  font-family: 'INTERMARCHE-Bold';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;

  cursor: pointer;
}

.np-radio {
  margin-left: 15px;
}

input[type='radio'] {
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  height: 20px;
  margin-right: 15px;
  width: 20px;
  -webkit-appearance: none;
  border-color: black;
  border-width: 2px;
  border-style: solid;
}

input[type='radio']:disabled {
  background-color: lightgray;
  border-radius: 10px;
  cursor: pointer;
  height: 20px;
  margin-right: 15px;
  width: 20px;
  -webkit-appearance: none;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  cursor: default;
}

input[type='radio']:focus {
  outline: none;
}

input[type='radio']:checked {
  border-color: white;
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px #ff1e24;
}

.np-radio-label-active {
  margin: 0px;
  color: white;
  font-family: 'Intermarche-Regular';
  font-size: 18px;
  line-height: 22px;

  cursor: pointer;
}

.np-radio-label {
  margin: 0px;
  /* color: black; */
  font-family: 'Intermarche-Regular';
  font-size: 18px;
  line-height: 22px;

  cursor: pointer;
}

.np-radio-label-disabled {
  cursor: default;
}

.np-checkboxes-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.np-checkboxes-subcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.np-checkbox-container-active {
  box-sizing: border-box;
  height: 60px;
  width: 206px;
  border: 2px solid #ff1e24;
  border-radius: 9px;
  background-color: #ff1e24;
  color: #000000;
  font-family: 'INTERMARCHE-Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  cursor: pointer;
}

.np-checkbox-container {
  box-sizing: border-box;
  height: 60px;
  width: 206px;
  border: 2px solid #000000;
  border-radius: 9px;
  background-color: #ffffff;
  color: #000000;
  font-family: 'INTERMARCHE-Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  cursor: pointer;
}

.np-checkbox {
  margin-left: 15px;
  margin-right: 15px;
}

.np-checkboxes-subcontainers-divider {
  margin-left: 30px;
}

.np-checkbox-label-active {
  margin: 0px;
  color: white;
  font-family: 'Intermarche-Regular';
  font-size: 18px;
  line-height: 22px;

  cursor: pointer;
}

.np-checkbox-label {
  margin: 0px;
  color: black;
  font-family: 'Intermarche-Regular';
  font-size: 18px;
  line-height: 22px;

  cursor: pointer;
}

input[type='checkbox'] {
  position: relative;
  cursor: pointer;
  margin-top: 0px;
  width: 15px;
}

input[type='checkbox']:before {
  content: '';
  width: 15px;
  height: 15px;
  top: 0;
  left: 0;
  border: 2px solid black;
  border-radius: 3px;
  background-color: white;
  position: absolute;
}

input[type='checkbox']:checked:after {
  content: '';
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 2px;
  left: 5px;
  position: absolute;
}

.np-input-error-msg {
  color: #ff6d00;
  font-family: 'INTERMARCHE-Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 7px;
}
