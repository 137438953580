.mn-container {
  align-self: flex-start;
  justify-self: flex-end;
  margin-right: 30px;
}

.mn-navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mn-vl {
  border-left: 2px solid #000000;
  height: 20px;
  margin-bottom: 20px;
}

.mn-a-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mn-a {
  padding-bottom: 20px;
  width: 180px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.mn-a:hover {
  text-decoration: none;
  color: black;
}

.mn-a-active {
  padding-bottom: 15px;
  color: var(--theme-color-red);
  border-bottom: solid var(--theme-color-red) 5px;
}

.mn-a-active:hover {
  color: var(--theme-color-red);
}

.mn-a-previous {
  color: #ffffff;
}

.mn-a-previous:hover {
  color: #ffffff;
}
