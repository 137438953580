.fs-img {
  position: absolute;
}

.fs-icon {
  position: absolute;
  z-index: 999;
  right: 17px;
  top: 17px;
  cursor: pointer;
}
