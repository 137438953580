@media (min-width: 1400px) {
  .cs-container {
    width: 560px;
    margin-left: 30px;
    margin-right: 30px;
    min-width: 560px;
  }
}

@media (max-width: 1400px) {
  .cs-container {
    width: 440px;
    margin-left: 30px;
    margin-right: 30px;
    min-width: 440px;
  }
}

.cs-subcontainer {
  overflow-y: auto;
}

.cs-title {
  border-bottom-width: 2px;
  border-bottom-color: black;
  border-bottom-style: solid;
}

.cs-h1 {
  font-family: 'INTERMARCHE-Bold';
  font-size: 36px;
  margin: 0px;
}

.cs-h1::first-line {
  line-height: 100px;
}
