.scene-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.scene-right {
  margin-right: 30px;
}
