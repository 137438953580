.usermanagement_maincontent{
  width: 90%;
  margin: auto;
  text-align: center;
  padding: 35px;
}
.usermanagement_tableheader{
  margin-bottom: 20px;
  text-align: left;
  font-weight: bold;
}



#filter-text-box {
  font-size: 12px;
  height: 30px;
}




/** Gestion du tableau de recherche **/

.grid-search-box{
  height: 35px;
  width: 100%;
  border-width: 1px;
  border-radius: 20px;
  padding: 0.25rem 3rem 0.25rem 3rem;
  background: url(../../images/icons/search.png) no-repeat 12px center;
  background-color: white;
  outline: medium none;
  font-size: 14px;
  vertical-align: middle;
}

.row_bottom_margin{
  margin-bottom: 15px ;
}

.page-size-select{
  width:45px;
  outline:none;
  box-shadow: none;
  margin-right: 10px;
  margin-left: 10px;
 }
.page-size-select:focus{
  width:35px;
  outline:none;
  box-shadow: none;
}

.page-size-select-row{
  text-align: right;
  margin: 10px;
}

.action-button{
  cursor:pointer;
}