.mp-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mp-header {
  background: url('../../assets/images/KRAFT.png') repeat top center;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'INTERMARCHE-Bold';
}

.mp-header-logo {
  justify-self: flex-start;
  margin-left: 37px;
}

.mp-header-logo-img {
  vertical-align: middle;
}

.mp-header-title {
  justify-self: flex-start;
  margin-left: 20px;
  /* width: 265px; */
  height: 126px;
  font-size: 40px;
  line-height: 42px;
}

.mp-header-title span {
  color: var(--theme-color-red);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .mp-header-heading {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;

    height: 43px;
    width: 361px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .mp-header-heading {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    height: 43px;
    width: 361px;
    color: #000000;
    font-family: 'INTERMARCHE-Bold';
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center;
    margin-bottom: 0px;
  }
}

.mp-projects-container {
  font-family: 'INTERMARCHE-Bold';
  margin-top: 20px;
  width: 915px;
  background-color: #f6f6f6;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 118px;
  padding-right: 118px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mp-project-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mp-projects-title {
  width: 679px;
}

.mp-projects-title-p {
  font-size: 20px;
  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.mp-project {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 70px;
  width: 679px;
  background-color: #ffffff;

  margin-bottom: 5px;
  text-decoration: none;
}

.mp-project:hover {
  text-decoration: none;
}

.mp-project-title {
  margin-left: 25px;
}

.mp-project-title-date {
  margin: 0px;
  color: #ff1e24;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.mp-project-title-name {
  margin: 0px;

  color: #000000;
  font-family: 'INTERMARCHE-Bold';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.mp-project-arrow {
  margin-right: 25px;
  height: 40px;
  width: 40px;
}
